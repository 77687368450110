import React from "react";
import { Nav, NavbarContainer, NavLogo, NavIcon } from "./NavbarStyle";
import { animateScroll as scroll } from "react-scroll";
import BizLogo from "../../images/bizLogo.png";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { NavButton } from "../../GlobalStyles";

const theme = createTheme({
  palette: {
    webTheme: {
      main: "#b48811",
      contrastText: "#fff",
    },
  },
});

const Navbar = () => {
  return (
    <Box sx={{ flexGrow: 1, color: "transparent", width: "100%" }}>
      <AppBar
        style={{ width: "100%" }}
        position="fixed"
        sx={{ color: "transparent", background: "transparent" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box sx={{ marginLeft: "5px" }}>
            <NavLogo to="/" onClick={scroll.scrollToTop}>
              <NavIcon src={BizLogo} alt="" />
            </NavLogo>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
              width: "100%",
            }}
          >
            <ThemeProvider theme={theme}>
              <NavButton
                sx={{
                  justifyContent: "end",
                  color: "white",
                  alignItems: "flex-end",
                  fontFamily: "sans-serif",
                  fontWeight: "600",
                  strokeWidth: "2px",
                }}
                variant="outlined"
                color="webTheme"
              >
                Request Now
              </NavButton>
            </ThemeProvider>
          </Box>
        </Box>
      </AppBar>
    </Box>
  );
};

export default Navbar;
