import React, { useEffect, useState, useRef } from "react";
import { useInView } from "react-intersection-observer";
import TypeWriterEffect from "react-typewriter-effect";
import Navbar from "../Navbar/Navbar";
import {
  HeroSection,
  HeroContent,
  HeroImage,
  HeroSlide,
  HeroSlider,
  HeroWrapper,
} from "./HeroStyle";

const Hero = ({ slides }) => {
  const { ref, inView } = useInView({
    rootMargin: "-80px",
  });

  const [current, setCurrent] = useState(0);
  const length = slides.length;
  const timeout = useRef(null);

  useEffect(() => {
    const nextSlide = () => {
      setCurrent((current) => (current === length - 1 ? 0 : current + 1));
    };

    timeout.current = setTimeout(nextSlide, 3000);

    return function () {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [current, length]);

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }

  return (
    <>
      <Navbar />
      <HeroSection ref={ref} id="hero">
        <HeroWrapper>
          {slides.map((slide, index) => {
            return (
              <HeroSlide key={index}>
                {index === current && (
                  <HeroSlider>
                    <HeroImage src={slide.image} alt={slide.alt} />
                    <HeroContent>
                      {/* <h1>Launch Your Online</h1>
                      <h1>presence today</h1> */}
                      <TypeWriterEffect
                        textStyle={{
                          fontFamily: "sans-serif",
                          background:
                            " linear-Gradient(90deg, #b48811, #bb9b49, #ebd197)",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                          color: "#fff",
                          fontWeight: 600,
                          fontSize: "2em",
                        }}
                        startDelay={2000}
                        cursorColor="#3F3D56"
                        // multiText={["Your website", "Your app", "Your vision"]}
                        multiText={["Website", "Under", "Construction"]}
                        multiTextDelay={1000}
                        typeSpeed={50}
                        multiTextLoop
                      />
                      {/* <h1>With Us..</h1>
                      <h1>Completely Free !!!</h1> */}
                    </HeroContent>
                  </HeroSlider>
                )}
              </HeroSlide>
            );
          })}
        </HeroWrapper>
      </HeroSection>
    </>
  );
};

export default Hero;
