import React from "react";
import Hero from "../components/hero/Hero";
import { HeroData } from "../components/hero/HeroData";
import AboutUs from "../components/aboutus/AboutUs";

const LandingPage = () => {
  return (
    <>
      <Hero slides={HeroData} />
      {/* <AboutUs /> */}
    </>
  );
};

export default LandingPage;
