import styled, { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
 *{
   box-sizing: border-box;
   margin: 0;
   padding: 0;
   font-family: 'Montserrat', sans-serif;;
 }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1330px;
  margin: 0 auto;
  padding: 0 50px;

  @media screen and (max-width: 960px) {
    padding: 0 30px;
  }
`;

export const NavButton = styled.button`
  border-radius: 4px;
  display: flex;
  justify-content: end;
  align-self: flex-end;
  align-items: flex-end;
  background: none;
  margin-right: 1rem;
  white-space: nowrap;
  padding: ${({ big }) => (big ? "12px 64px" : "10px 20px")};
  font-weight: 600;
  color: #fff;
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: 2px solid #d4af37;
  font-family: sans-serif;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  &:before {
    background: #d4af37;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    transition: all 0.6s ease;
    width: 102%;
    height: 0%;
  }

  &:hover:before {
    height: 200%;
  }

  &:hover {
    z-index: 1;
    color: black;
  }
`;

export const Section = styled.div`
  color: white;
  padding: 160px;
  background: ${({ inverse }) => (inverse ? "black" : "white")};
`;

export default GlobalStyle;
